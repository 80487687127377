






































































import { Component } from 'vue-property-decorator';
import LessonLayoutsToolbar from './LessonLayoutsToolbar.vue';

@Component
export default class LessonLayoutsBottomToolbar extends LessonLayoutsToolbar {
}
